import React, { useState, useEffect, useRef } from 'react'
import { Flex, Button, Input, Text, useToast } from '@chakra-ui/react';
import { bk2, font1 } from '../localVars';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../store/authSlice';
import { Icon } from '@iconify/react';
import axios from 'axios';
export default function Register() {
    const usernameRef = useRef('');
    const passwordRef = useRef('');
    const rePasswordRef = useRef('');
    const fullNameRef = useRef('');
    const avatarRef = useRef('');


    const dispatch = useDispatch();
    const { respRegister, isLoading, isLogged } = useSelector((state) => state.auth)
    const toast = useToast();



    const handleRegister = () => {
        const username = usernameRef.current.value;
        const password = passwordRef.current.value;
        const rePassword = rePasswordRef.current.value;
        const full_name = fullNameRef.current.value;

        if (username === '' || full_name === '' || password === '' || rePassword === '') {
            toast({
                title: 'Error',
                description: 'Please fill out all fields',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
            return;
        }
        if (password !== rePassword) {
            toast({
                title: 'Error',
                description: 'Passwords do not match',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
            return;
        }
        if (password.length < 8) {
            toast({
                title: 'Error',
                description: 'Password must be at least 8 characters long',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
        }
        const data = { username, password, full_name, avatar: imageUrl }
        dispatch(register(data));
    }
    console.log(typeof respRegister)
    useEffect(() => {
        if (respRegister !== null) {
            try {
                if (respRegister.message === 'User registered successfully') {
                    window.location.href = '/login';
                }
                else {
                    toast({
                        title: respRegister,
                        status: 'error',
                        duration: 1500,
                        isClosable: true,
                        position: 'top',
                    })
                }
            } catch (error) {
                toast({
                    title: respRegister,
                    status: 'error',
                    duration: 1500,
                    isClosable: true,
                    position: 'top',
                })
            }



        }

    }, [respRegister, dispatch])
    useEffect(() => {
        if (isLogged) {
            window.location.href = '/profile';
        }
    }, [isLogged])


    const [imageUrl, setImageUrl] = useState('');
    const [loadingImage, setLoadingImage] = useState('false');
    const handleFileChange = async (event) => {
        setLoadingImage('true');
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            try {
                const response = await axios.post('https://api.igstore.io/upload-image/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setLoadingImage('done');
                setImageUrl(response.data.url);

            } catch (error) {
                toast({
                    title: 'Error uploading the image.',
                    description: 'There was an error uploading your image.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };
    const handleImageClick = () => {
        document.getElementById('image-upload-input').click();
    };
    return (
        <Flex
            borderRadius={16}
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
            width={'400px'}
            height={'500px'}
            mt={'200px'}
            dir='rtl'
            flexDir={'column'}
            alignItems={'center'}
        >
            <Text
                fontFamily={font1}
                fontSize={20}
                alignSelf={'center'}
                width={'80%'}
                mt={6}
            >إنشاء حساب جديد</Text>
            <Input
                width={'80%'}
                mt={6}
                placeholder='الأسم واللقب'
                fontFamily={font1}
                ref={fullNameRef}

            />
            <Flex width={'80%'} mt={{ base: 6, md: 3 }} pt={6} height={'30px'} pb={10} alignItems={'center'} mb={-3}>

                <Input
                    type="file"
                    onChange={handleFileChange}
                    pt={1}
                    placeholder='Image'
                    width={'fit'}
                    id="image-upload-input"
                    display={'none'}
                />
                <Button
                    rightIcon={loadingImage === 'false' ? <Icon icon={'ci:cloud-upload'} width={20} /> : loadingImage === 'true' ? <Icon icon={'svg-spinners:bars-rotate-fade'} width={20} /> : <Icon icon={'mdi:success'} width={20} />}
                    colorScheme='purple'
                    variant={'outline'}
                    onClick={handleImageClick}
                    width={'100%'}
                    _active={{ transform: 'scale(0.95)', }}
                >إرفاق الصورة الشخصية</Button>
            </Flex>
            <Input
                width={'80%'}
                mt={6}
                placeholder='اسم المستخدم'
                fontFamily={font1}
                ref={usernameRef}
            />

            <Input
                width={'80%'}
                mt={6}
                placeholder='كلمه المرور'
                fontFamily={font1}
                ref={passwordRef}
                type='password'

            />
            <Input
                width={'80%'}
                mt={6}
                placeholder='تأكيد كلمة المرور'
                fontFamily={font1}
                ref={rePasswordRef}
                type='password'

            />
            <Button
                fontSize={18}

                padding={6}
                fontFamily={font1}
                bgColor={bk2}
                color={'white'}
                _hover={{ opacity: 0.7 }}
                mt={6}
                onClick={handleRegister}
            >{isLoading ? <Icon icon={'eos-icons:bubble-loading'} width={30} height={30} /> : 'إنشاء حساب جديد'}</Button>
        </Flex>
    )
}
