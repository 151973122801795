import React, { useState, useEffect } from 'react'
import { AspectRatio, Avatar, Box, Flex, Text, Image, Input, Button, useDisclosure } from '@chakra-ui/react';
import Sidebar from '../components/Sidebar';
import { bk2, font1 } from '../localVars';
import Question from '../components/Question';
import arrow_up from '../assets/images/arrow-up.png';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import AvatarName from '../components/AvatarName';
const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
};
export default function Lesson() {
    const [resp, setResp] = useState(null);
    const [showExercises, setShowExercises] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        if (resp === null) {
            fetch('https://dev.zyll.shop/lesson/')
                .then((response) => response.json())
                .then((data) => {
                    setResp(data);
                })
        }
    }, [resp])
    return (
        <Flex direction="row-reverse" minH="100vh" width={'100%'} color={'#fff'}>
            <Sidebar page={'Lessons'} />

            <Flex flex="1" width={'84%'} flexDir={'column'} alignItems={'start'} color={'black'} pb={10} mr="16%" overflowY="auto">
                <Flex
                    width={'94%'}
                    alignSelf={'center'}
                    mt={6}
                    justifyContent={'space-between'}
                    color={'black'}
                >
                    <AvatarName />
                </Flex>

                <Text
                    fontFamily={font1}
                    dir='rtl'
                    mt={20}
                    width={'86%'}
                    fontSize={20}
                    pb={20}
                >قم بمشاهدة المقطع ثم أجب على الأسئلة:</Text>

                <AspectRatio
                    maxW='560px'
                    ratio={1}
                    alignSelf='center'
                    mt={16}
                    width={'540px'}
                    height={'320px'}
                    borderWidth={'10px'}
                    borderColor={'black'}
                    borderRadius={8}

                >
                    <iframe
                        title='naruto'
                        src={'https://www.youtube.com/embed/CLzEQpK0zlc'}
                        allowFullScreen
                    />
                </AspectRatio>

                <Flex width={'420px'} justifyContent={'space-between'} alignItems={'center'} alignSelf={'center'} dir='rtl'>
                    <Button
                        fontFamily={font1}
                        bgColor={'#22078A'}
                        color={'#fff'}
                        mt={6}
                        width={'180px'}
                        height={'50px'}
                        fontSize={14}
                        borderRadius={8}
                        _hover={{ opacity: 7 }}
                        onClick={onOpen}
                    >عرض ملخص الدرس</Button>
                    <Button
                        fontFamily={font1}
                        bgColor={'#22078A'}
                        color={'#fff'}
                        mt={6}
                        width={'180px'}
                        height={'50px'}
                        fontSize={14}
                        borderRadius={8}
                        _hover={{ opacity: 7 }}
                        onClick={() => setShowExercises(true)}
                        as="a" href="#exercises"
                    >إنتقل للتمارين التفاعلية</Button>
                </Flex>

                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalContent mt={'200px'} mr={'120px'} maxW={'600px'} pt={4} pb={'120px'} dir='rtl'>
                        <ModalCloseButton />
                        <ModalHeader fontFamily={font1} mt={10}>الأعراب والبناء</ModalHeader>
                        <ModalBody>
                            <Text fontFamily={font1} fontSize={18} >{resp !== null && resp.summary}</Text>
                        </ModalBody>


                    </ModalContent>
                </Modal>

                <Flex
                    id="exercises"
                    width={'86%'}
                    flexDir={'column'}
                    alignItems={'center'}
                    alignSelf={'center'}
                    mt={4}
                    display={showExercises ? 'block' : 'none'}
                    justifyContent={'center'}
                >
                    {resp !== null && resp.questions.map((item, index) => <Question question={item.question} options={shuffleArray(item.answers)} index={index} correct_answer={item.correct_answer} />)}

                </Flex>

                <Text
                    width={'86%'}
                    dir='rtl'
                    fontFamily={font1}
                    fontSize={20}
                    mt={'120px'}
                    pb={4}
                    display={showExercises ? 'block' : 'none'}

                >
                    التمرين الثاني
                </Text>
                <Flex
                    width={'70%'}
                    display={showExercises ? 'flex' : 'none'}
                    mt={4}
                    flexDir={'column'}
                    borderRadius={16}
                    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                    height={'400px'}
                    alignSelf={'center'}
                    alignItems={'center'}

                >
                    <Text mt={20} fontFamily={font1} fontSize={22}>قم بتحليل النص واستخرج الفاعل والمفعول به مع الإعراب </Text>
                    <Text fontFamily={font1} fontSize={22} mt={10}>
                        (("<Box dir={'rtl'} as='spin' color={'#2908aa'}>{resp !== null && resp.sentence_for_parsing}</Box>"))
                    </Text>
                </Flex>

                <Flex

                    width={'70%'}
                    alignSelf={'center'}
                    display={showExercises ? 'flex' : 'none'}
                    mt={10}
                    alignItems={'center'}
                >
                    <Flex
                        ml={10}
                        bgColor={'black'}
                        width={'60px'}
                        height={'60px'}
                        borderRadius={120}
                        justifyContent={'center'}
                        alignItems={'center'}
                        position={'absolute'}
                        cursor={'pointer'}
                        zIndex={2}
                    >
                        <Image src={arrow_up} width={'26px'} height={'26px'} />
                    </Flex>
                    {/* <Text
                        fontFamily={font1}
                        fontSize={20}
                        color={'#666666'}
                        mr={20}
                    >اكتب إجابتك هنا</Text> */}

                    <Input
                        fontFamily={font1}
                        fontSize={20}
                        color={'#666666'}
                        borderWidth={'0px'}
                        width={'100%'}
                        zIndex={1}

                        dir='rtl'

                        placeholder='إكتب اجابتك هنا'

                        borderRadius={120}
                        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                        alignSelf={'center'}
                        display={showExercises ? 'flex' : 'none'}
                        height={'80px'}
                        pr={10}

                        position={'relative'}

                    />
                </Flex>

            </Flex>
        </Flex>
    )
}
