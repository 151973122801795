import React, { useState } from 'react'
import { Flex, Text, Box, Button, Image } from '@chakra-ui/react';
import AvatarName from '../components/AvatarName';
import Sidebar from '../components/Sidebar';
import { font1 } from '../localVars';
import people from '../assets/images/full-the-blank.png'
const data = [
    {
        "question": "الكتاب الذي على الطاولة ........؟",
        "answers": ['له', 'لها', 'لهم', 'لهن'],
        "correct": 'له'
    },
    {
        "question": "السيارة التي في المرآب ........؟",
        "answers": ['لهم', 'لها', 'له', 'لهن'],
        "correct": 'لها'
    },
    {
        "question": "البيت الذي على الجبل ........؟",
        "answers": ['له', 'لها', 'لهم', 'لكم'],
        "correct": 'له'
    },
    {
        "question": "الحديقة التي خلف البيت ........؟",
        "answers": ['لها', 'له', 'لهم', 'لنا'],
        "correct": 'لها'
    }
];
export default function FullBlank() {
    const [page, setPage] = useState('view');

    return (
        <Flex direction="row-reverse" minH="100vh" width={'100%'} color={'#fff'}>
            <Sidebar page={'Home'} />
            {page === 'view' ? <View setPage={setPage} /> : <Challange page={page} setPage={setPage} />}

        </Flex>
    )
}

const View = ({ setPage }) => {
    return (
        <Flex flex="1" width={'84%'} flexDir={'column'} alignItems={'start'} color={'black'} pb={10} mr="16%" overflowY="auto">
            <Flex
                width={'94%'}
                alignSelf={'center'}
                mt={6}
                justifyContent={'space-between'}
                color={'black'}
            >
                <AvatarName />
            </Flex>


            <Image
                src={people}
                width={'160px'}
                alignSelf={'center'}
                mt={12}
            />
            <Text
                fontFamily={font1}
                fontSize={24}
                alignSelf={'center'}
                color={'#2B1875'}
                mt={10}

            >تمرين إملأ الفراغ </Text>

            <Text
                fontFamily={font1}
                fontSize={24}
                textAlign={'center'}
                alignSelf={'end'}
                width={'56%'}
                mt={10}
                dir='rtl'

            >إرشادات التمرين:</Text>


            <Flex dir='rtl' alignSelf={'center'} mt={10} alignItems={'center'} width={'66%'}>
                <Box height={'6px'} width={'6px'} bgColor={'black'} ml={4} mt={2}></Box>
                <Text
                    alignSelf={'center'}

                    fontFamily={font1}
                    fontSize={24}
                >تأكد من فهم معنى كامل نوع الكلمة المطلوبة (فعل، اسم، أو غيرها).</Text>
            </Flex>

            <Flex dir='rtl' alignSelf={'center'} mt={2} alignItems={'center'} width={'66%'}>
                <Box height={'6px'} width={'6px'} bgColor={'black'} ml={4} mt={2}></Box>
                <Text
                    alignSelf={'center'}
                    width={'100%'}
                    fontFamily={font1}
                    fontSize={24}
                >استخدم الخيارات المتاحة لإكمال الجملة</Text>
            </Flex>
            <Flex dir='rtl' alignSelf={'center'} mt={2} alignItems={'center'} width={'66%'}>
                <Box height={'6px'} width={'6px'} bgColor={'black'} ml={4} mt={2}></Box>
                <Text
                    alignSelf={'center'}
                    width={'100%'}
                    fontFamily={font1}
                    fontSize={24}
                >بعد انتهاء التمرين، سيوضح لك النظام الإجابة الصحيحة</Text>
            </Flex>



            <Button
                bgColor={'#1B0378'}
                alignSelf={'center'}
                mt={10}
                color={'#fff'}
                fontFamily={font1}
                fontSize={24}
                width={'220px'}
                height={'54px'}
                borderRadius={20}
                _hover={{ opacity: 0.7 }}
                onClick={() => setPage('challange')}
            >
                البدء
            </Button>
        </Flex>
    )
}

const Challange = ({ page, setPage }) => {
    return (
        <Flex flex="1" width={'84%'} flexDir={'column'} alignItems={'start'} color={'black'} pb={10} mr="16%" overflowY="auto">
            <Flex
                width={'94%'}
                alignSelf={'center'}
                mt={6}
                justifyContent={'space-between'}
                color={'black'}
            >
                <AvatarName />
            </Flex>

            <Text
                fontFamily={font1}
                fontSize={24}
                alignSelf={'center'}
                mt={'80px'}
            >تمرين إملأ الفراغ</Text>
            {page === 'challange' ? <Question setPage={setPage} /> : <Results />}

        </Flex>
    )
}

const Question = ({ setPage }) => {
    const [num, setNum] = useState(0)

    const handleNext = () => {
        if (num === 3) {
            setPage('results')
        }
        else {
            setNum(num + 1)
        }
    }
    return (
        <>
            <Flex
                boxShadow="0px 3.51px 20.21px 0px #1B0378"
                width={'660px'}
                height={'180px'}
                borderRadius={12}
                alignSelf={'center'}
                mt={12}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Text
                    fontFamily={font1}
                    fontSize={20}
                >
                    {data[num].question}
                </Text>
            </Flex>

            <Flex
                alignSelf={'center'}
                width={'70%'}
                justifyContent={'space-between'}
                mt={20}

            >
                <Answer txt={data[num].answers[0]} onClick={handleNext} />
                <Answer txt={data[num].answers[1]} onClick={handleNext} />
            </Flex>
            <Flex
                alignSelf={'center'}
                width={'70%'}
                justifyContent={'space-between'}
                mt={10}

            >
                <Answer txt={data[num].answers[2]} onClick={handleNext} />
                <Answer txt={data[num].answers[3]} onClick={handleNext} />
            </Flex>
        </>

    )
}
const Answer = ({ txt, onClick }) => {
    return (
        <Flex
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
            width={'380px'}
            height={'110px'}
            borderRadius={12}
            justifyContent={'center'}
            alignItems={'center'}
            onClick={onClick}
            cursor={'pointer'}
        >
            <Text fontFamily={font1} fontSize={20}>{txt}</Text>
        </Flex>
    )
}

const Results = () => {
    return (
        <Flex flexDir={'column'} dir='rtl' alignItems={'center'} width={'100%'}>
            <Text fontFamily={font1} fontSize={22} width={'70%'} mt={10}>الإجابات الخاطئة التصحيح:</Text>
            <Flex
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                width={'70%'}
                height={'200px'}
                mt={4}
                borderRadius={12}
            ></Flex>

            <Text fontFamily={font1} fontSize={22} width={'70%'} mt={6}>التوضيح:</Text>
            <Flex
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                width={'70%'}
                height={'200px'}
                mt={4}
                borderRadius={12}
            ></Flex>
        </Flex>
    )
}