import React, { useState, useEffect } from 'react'
import { Flex, Image, Text, Button, Box } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import { font1 } from '../localVars';
import next_png from '../assets/images/next.png'
import success_png from '../assets/images/success.png'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
export default function CradsExercise({ setPage, setNumLine }) {
    const [showComponent, setShowComponent] = useState('view')
    const handleNext = () => {
        setShowComponent('challange')
    }

    useEffect(() => {
        if (showComponent === 'finished') {
            setNumLine(2)
        }
    }, [showComponent, setShowComponent])
    return (
        <>
            <Flex
                alignSelf={'end'}
                mt={16}
                flexDir={'column'}
                dir='rtl'
                width={'80%'}
                mr={10}
                display={showComponent === 'view' ? 'flex' : 'none'}
                alignItems={'center'}
            >
                <Text fontFamily={font1} fontSize={22} color={'#000'}>التمرين الأول </Text>

                <Flex flexDir={'column'} mt={16}>
                    <Text fontFamily={font1} fontSize={22} pb={4}>إرشادات التمرين:</Text>
                    <TextBox txt={'ستظهر لك مجموعة بطاقات تحتوي على كلمات  اسحب البطاقة لمكانها المناسب'} />
                    <TextBox txt={'ركز على الكلمات وحاول سحب اعراب كل كلمة لمكانها الصحيح'} />
                </Flex>

                <Flex mt={20} alignItems={'center'} cursor={'pointer'} onClick={handleNext}>
                    <Text fontFamily={font1} fontSize={24} ml={8}>إنتقل للأسئلة</Text>
                    <Image src={next_png} width={'60px'} transform="rotate(180deg)" />
                </Flex>
            </Flex>

            <Flex
                alignSelf={'end'}
                mt={16}
                flexDir={'column'}
                dir='rtl'
                width={'80%'}
                mr={10}
                display={showComponent === 'challange' ? 'flex' : 'none'}
                alignItems={'center'}
            >
                <Text fontFamily={font1} fontSize={24}>الجملة : الطالب يقرأ الكتاب في المكتبة</Text>

                <Cards showComponent={showComponent} setShowComponent={setShowComponent} />
            </Flex>


            <Flex
                alignSelf={'end'}
                mt={16}
                flexDir={'column'}
                dir='rtl'
                width={'80%'}
                mr={10}
                display={showComponent === 'finished' ? 'flex' : 'none'}
                alignItems={'center'}
            >
                <Image
                    src={success_png}
                    width={'180px'}
                />
                <Text fontFamily={font1} fontSize={24} mt={12}>أحسنت</Text>
                <Text fontFamily={font1} fontSize={24} mt={12}> والأن انتقل إلى التمرين الثاني </Text>
                <Image
                    src={next_png}
                    transform="rotate(180deg)"
                    w={'60px'}
                    alignSelf={'end'}
                    ml={10}
                    cursor={'pointer'}
                    onClick={() => setPage('gmail')}
                />
            </Flex>
        </>
    )
}

const TextBox = ({ txt }) => {
    return (
        <Flex
            alignItems={'center'}
            mr={-8}
            mt={2}
        >
            <Box height={'6px'} width={'6px'} bgColor={'black'} mt={2} ml={3}></Box>
            <Text fontFamily={font1} fontSize={22}>{txt}</Text>
        </Flex>
    )
}



const initialCards = [
    { id: 1, content: 'مبتدأ' },
    { id: 2, content: 'فعل' },
    { id: 3, content: 'مفعول به' },
    { id: 4, content: 'جار ومجرور' },
];

const initialSentence = {
    subject: null,
    verb: null,
    object: null,
    preposition: null,
};

function Cards({ showComponent, setShowComponent }) {
    const [cards, setCards] = useState(initialCards);
    const [sentence, setSentence] = useState(initialSentence);

    const onDragEnd = (result) => {
        const { source, destination } = result;

        // If dropped outside any droppable area, do nothing
        if (!destination) return;

        // Convert draggableId to number since card ids are numbers
        const draggedCard = cards.find(card => card.id === parseInt(result.draggableId, 10));

        // Update the sentence based on where the card is dropped
        const updatedSentence = { ...sentence };
        if (destination.droppableId === 'subject-drop') {
            updatedSentence.subject = draggedCard;
        } else if (destination.droppableId === 'verb-drop') {
            updatedSentence.verb = draggedCard;
        } else if (destination.droppableId === 'object-drop') {
            updatedSentence.object = draggedCard;
        } else if (destination.droppableId === 'preposition-drop') {
            updatedSentence.preposition = draggedCard;
        }

        // Remove the card from the available cards list
        const updatedCards = cards.filter(card => card.id !== draggedCard.id);

        setSentence(updatedSentence);
        setCards(updatedCards); // Update the cards to reflect the removed one
        if (updatedCards.length === 0) {
            setShowComponent('finished')
        }
    };

    return (
        <Flex p={5} width={'100%'} flexDir={'column'} alignItems={'center'}>

            <DragDropContext onDragEnd={onDragEnd}>
                <Flex mb={5} flexDir={'column'} width={'70%'}>
                    {/* Drop areas */}
                    <Flex justify="space-between" >
                        <DropArea id="subject-drop" label="الطالب" card={sentence.subject} />
                        <DropArea id="verb-drop" label="يقرأ" card={sentence.verb} />
                    </Flex>

                    <Flex justify="space-between" mt={8}>
                        <DropArea id="object-drop" label="الكتاب" card={sentence.object} />
                        <DropArea id="preposition-drop" label="في المكتبة" card={sentence.preposition} />
                    </Flex>
                </Flex>
                <Text fontFamily={font1} fontSize={26} alignSelf={'center'} w={'80%'} pb={10}>اسحب كل بطاقة لمكانها الصحيح</Text>
                <Droppable droppableId="cards">
                    {(provided) => (
                        <Flex ref={provided.innerRef} {...provided.droppableProps} justify="center">
                            {cards.map((card, index) => (
                                <Draggable key={card.id} draggableId={card.id.toString()} index={index}>
                                    {(provided) => (
                                        <Flex
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            bg="#3D249D"
                                            color="white"
                                            m={2}
                                            width={'90px'}
                                            height={'120px'}
                                            rounded="md"
                                            cursor="pointer"
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            padding={1}
                                        >
                                            <Text fontFamily={font1} fontSize={20} textAlign="center">{card.content}</Text>
                                        </Flex>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Flex>
                    )}
                </Droppable>
            </DragDropContext>
        </Flex>
    );
}

function DropArea({ id, label, card }) {
    return (
        <Droppable droppableId={id}>
            {(provided) => (
                <Flex alignItems={'center'}>
                    <Text fontFamily={font1} fontSize={22} ml={6}>{label}</Text>
                    <Flex
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        w={'90px'}
                        h="120px"
                        borderWidth={'3px'}
                        borderRadius={8}
                        borderColor="#000"
                        bgColor={card ? '#3D249D' : '#fff'}
                        color={'#fff'}
                        m={2}
                        p={1}
                        alignItems="center"
                        justifyContent={'center'}
                    >
                        <Text fontFamily={font1} fontSize={20} textAlign={'center'}>{card ? card.content : ''}</Text>
                        {provided.placeholder}
                    </Flex>
                </Flex>
            )}
        </Droppable>
    );
}

