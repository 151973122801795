import React from 'react'
import { Flex, Text, Image, useDisclosure, Button } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { font1 } from '../localVars';
import touch from '../assets/images/touch.png';
import Cookies from 'js-cookie'
import video_png from '../assets/images/video.png'
import text_png from '../assets/images/text.png'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
export default function Lesson2({ id, color, title, minutes, info }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const handleClick = (id) => {
        fetch('https://dev.zyll.shop/join-lesson/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('access_token')}`,
            },
            body: JSON.stringify({
                lesson: id
            }),
        })
            .then((response) => {
                if (response.status === 201) {
                    window.location.href = 'http://localhost:3000/profile';
                } else {
                    console.error('Failed to join lesson. Status code:', response.status);
                    // You can handle other status codes or errors here.
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                // Handle network or other errors here.
            });
    }
    return (
        <Flex
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
            borderRadius={8}
            justifyContent={'space-between'}
            mt={5}
            width={'90%'}

        >
            <Flex width={'50px'} bgColor={color} height={'200px'} borderTopRightRadius={8} borderBottomRightRadius={8}></Flex>
            <Flex width={'70%'} flexDir={'column'}>
                <Text fontSize={18} fontFamily={font1} mt={10} mr={8}>{title}</Text>
                <Text fontFamily={font1} mt={8} mr={8} alignSelf={'center'} textAlign={'center'}>{info}</Text>
            </Flex>
            {/* <Flex width={'5%'} bgColor={'silver'} >

            </Flex> */}
            <Flex
                width={'20%'}
                flexDir={'column'}
                alignItems={'center'}
            >
                <Flex
                    mt={10}
                    borderRadius={8}
                    bgColor={color}
                    height={'90px'}
                    width={'90px'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    color={'white'}
                >
                    <Text fontSize={18} fontFamily={font1}>{minutes}</Text>

                </Flex>
                <Text fontFamily={font1} fontSize={14} mt={1}>دقيقة</Text>

                <Flex height={'30px'} width={'100px'} ml={'180px'} cursor={'pointer'} onClick={onOpen}>
                    <Text fontFamily={font1}>إبدأ الأن</Text>
                    <Image
                        width={'26px'}
                        height={'26px'}
                        src={touch}
                        mr={2}
                    />
                </Flex>

                <Modal isOpen={isOpen} onClose={onClose} >
                    <ModalOverlay />
                    <ModalContent mt={'200px'} mr={'120px'} maxW={'480px'} pt={8} pb={16}>
                        <ModalHeader textAlign={'center'} fontFamily={font1}>اختر طريقة عرض الدرس</ModalHeader>
                        <ModalBody>
                            <Flex
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                width={'100%'}
                                alignSelf={'center'}
                                dir='rtl'
                                mt={8}
                                pr={2}
                                pl={2}
                            >
                                <Flex
                                    flexDir={'column'}
                                    width={'180px'}
                                    height={'200px'}
                                    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                                    borderRadius={16}
                                    alignItems={'center'}

                                >
                                    <Text fontFamily={font1} mt={6} fontSize={14}>مقطع فيديو</Text>
                                    <Image
                                        src={video_png}
                                        width={'50px'}
                                        height={'50px'}
                                        mt={4}
                                    />

                                    <Button
                                        fontFamily={font1}
                                        bgColor={'#22078A'}
                                        color={'#fff'}
                                        mt={6}
                                        width={'120px'}
                                        height={'30px'}
                                        borderRadius={8}
                                        _hover={{ opacity: 7 }}
                                        onClick={() => handleClick(id)}
                                    >إبدا الدرس</Button>
                                </Flex>
                                <Flex
                                    flexDir={'column'}
                                    width={'180px'}
                                    height={'200px'}
                                    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                                    borderRadius={16}
                                    alignItems={'center'}
                                >
                                    <Text fontFamily={font1} mt={6} fontSize={14}>محتوى كتابي (نصي)</Text>
                                    <Image
                                        src={text_png}
                                        width={'50px'}
                                        height={'50px'}
                                        mt={4}
                                    />

                                    <Button
                                        fontFamily={font1}
                                        bgColor={'#22078A'}
                                        color={'#fff'}
                                        mt={6}
                                        width={'120px'}
                                        height={'30px'}
                                        borderRadius={8}
                                        _hover={{ opacity: 7 }}
                                    >إبدا الدرس</Button>
                                </Flex>
                            </Flex>
                        </ModalBody>


                    </ModalContent>
                </Modal>
            </Flex>


        </Flex>
    )
}
