import React, { useState, useEffect } from 'react'
import { Flex, Text, Box, Button, Image, Avatar, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import Sidebar from '../components/Sidebar';
import { bk2, bk3, font1, fr1 } from '../localVars';
import BtnLesson from '../components/BtnLesson';
import Lesson2 from '../components/Lesson2';
import AvatarName from '../components/AvatarName';
import Cookies from 'js-cookie'
// const lessons = [
//     {
//         'title': 'درس المبتدأ والخبر ',
//         'info': 'يشرح الدرس كيفية تحديد المبتدأ والخبر في الجملة الاسمية وإعرابهما.',
//         'color': '#22078a',
//         'minutes': '12',
//         'img': 'https://images.pexels.com/photos/733857/pexels-photo-733857.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
//     },
//     {
//         'title': 'درس الجار والمجرور',
//         'info': 'يشرح علاقة الجار والمجرور ودورهما في الجملة وإعرابهما.',
//         'color': '#084f4d',
//         'minutes': '12',
//         'img': 'https://images.pexels.com/photos/733857/pexels-photo-733857.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
//     },
//     {
//         'title': 'درس الحال والتمييز ',
//         'info': 'يتناول الدرس كيفية استخدام التمييز لتوضيح المعنى، والحال لوصف هيئة الفاعل أو المفعول به عند وقوع الفعل.',
//         'color': '#7b9e19',
//         'minutes': '10',
//         'img': 'https://images.pexels.com/photos/733857/pexels-photo-733857.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
//     },
//     {
//         'title': 'درس المفاعيل الخمسة ',
//         'info': 'يشرح الدرس كل نوع من المفاعيل وطريقة التمييز بينها ووظائفها في الجملة وطريقة إعرابها',
//         'color': '#3d0c6e',
//         'minutes': '8',
//         'img': 'https://images.pexels.com/photos/733857/pexels-photo-733857.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
//     },

// ]
export default function Lessons() {
    const [lessons, setLessons] = useState(null);
    const access_token = Cookies.get('access_token');
    useEffect(() => {
        if (lessons === null) {
            if (access_token !== null || access_token !== undefined || access_token !== "") {
                fetch('https://dev.zyll.shop/lessons/', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + access_token
                    }
                })
                    .then((response) => response.json())
                    .then((data) => setLessons(data))
            }
            else {
                fetch('https://dev.zyll.shop/lessons/')
                    .then((response) => response.json())
                    .then((data) => setLessons(data))
            }
        }
    }, [lessons])
    return (
        <Flex direction="row-reverse" minH="100vh" width={'100%'} color={'#fff'}>
            <Sidebar page={'Lessons'} />

            <Flex flex="1" width={'84%'} flexDir={'column'} alignItems={'center'} pb={10} color={'black'} mr="16%" overflowY="auto">
                <Flex
                    width={'94%'}
                    alignSelf={'center'}
                    alignItems={'center'}
                    mt={6}
                    justifyContent={'space-between'}
                    color={'black'}
                >
                    <AvatarName />

                    <InputGroup width={'700px'} >
                        <Input borderRadius={12} height={'54px'} bgColor={bk3} color={'black'} />
                        <InputRightElement alignSelf={'center'} mt={2} mr={4}>
                            <Icon icon={'ic:outline-search'} color='black' width={28} />
                        </InputRightElement>
                    </InputGroup>

                    <Icon icon={'mdi:arrow-right'} width={40} />

                </Flex>

                <Flex width={'88%'} alignSelf={'center'} dir='rtl' mt={4}>
                    <Text
                        fontFamily={font1}
                        fontSize={20}

                    >قائمة الدروس</Text>

                </Flex>

                <Flex color={'#fff'} dir='rtl' width={'96%'} mt={10} alignItems={'center'}>

                    <Flex width={'200px'} justifyContent={'center'} ml={8}>
                        <Flex
                            width={'90px'}
                            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                            borderRadius={32}
                            height={'44px'}
                            dir='ltr'
                        >
                            <Flex
                                bgColor={'#061492'}
                                borderRadius={32}
                                justifyContent={'center'}
                                alignItems={'center'}
                                width={'44px'}
                                height={'44px'}

                            >
                                <Icon icon={'material-symbols-light:menu'} width={22} height={26} />
                            </Flex>
                        </Flex>
                    </Flex>

                    <BtnLesson txt={'جميع الدروس'} bk={'#061492'} />
                    <BtnLesson txt={'الدروس المكتملة'} bk={'#7a9d1a'} />
                    <BtnLesson txt={'الدروس التفاعلية'} bk={'#084f4d'} />
                    <BtnLesson txt={'الدروس المفضلة'} bk={'#3c0c6c'} />


                </Flex>

                <Flex
                    flexDir={'column'}
                    width={'88%'}
                    mt={14}
                    dir='rtl'
                >
                    {lessons !== null && lessons.map((item, i) => (
                        <Lesson2
                            id={item.id}
                            color={i === 0 || i === 4 || i === 8 ? '#22078a' : i === 1 || i === 5 || i === 9 ? '#084f4d' : i === 2 || i === 6 ? '#7b9e19' : i === 3 || i === 7 ? '#3d0c6e' : ''}
                            title={item.title}
                            info={item.description}
                            minutes={item.minutes}
                        />
                    ))}
                </Flex>
            </Flex>
        </Flex>
    )
}

const MyBox = ({ name, info, img }) => {
    return (
        <Flex
            width={'100%'}
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
            rounded={'md'}
            dir='rtl'
            height={'140px'}
            borderRadius={0}
            mt={6}
        >
            <Flex flexDir={'column'} width={'70%'} alignItems={'center'}>
                <Text
                    fontFamily={font1}
                    color={fr1}
                    fontSize={20}
                    mt={6}
                >{name}</Text>
                <Text
                    fontFamily={font1}
                    fontSize={20}
                    mt={6}
                >
                    {info}
                </Text>
            </Flex>
            <Flex width={'26%'} alignItems={'center'} justifyContent={'space-between'}>
                <Text
                    fontFamily={font1}
                    fontSize={20}
                >أكمل الدرس</Text>
                <Image src={img} width={140} height={120} borderRadius={8} />
            </Flex>
        </Flex>
    )
}