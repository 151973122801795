export const bk1 = '#ffffff';
export const bk2 = '#0a0128';
export const bk3 = '#d9d9d9';
export const bk4 = '#180564';
export const fr1 = '#453CA5';
export const fr2 = '#686868';




export const font1 = 'Cairo';
export const font2 = 'Inter-Variable';