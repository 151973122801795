import React from 'react'
import { Flex, Box, Text, Image } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import check_png from '../assets/images/check.png'
export default function LineExercises({ numLine }) {
    const bk_done = '#23116B';
    const bk_undone = '#D9D9D9';
    console.log('numLine')
    console.log(numLine)
    return (
        <Flex alignItems={'center'} dir='rtl' mt={12} alignSelf={'end'} mr={10} >
            <Box bgColor={bk_done} h={'40px'} w={'40px'} borderRadius={8}></Box>
            <Box bgColor={bk_done} h={'10px'} w={'180px'}></Box>

            <Box bgColor={numLine > 1 ? bk_done : bk_undone} h={'40px'} w={'40px'} borderRadius={8}></Box>
            <Box bgColor={numLine > 1 ? bk_done : bk_undone} h={'10px'} w={'180px'}></Box>

            <Box bgColor={numLine > 2 ? bk_done : bk_undone} h={'40px'} w={'40px'} borderRadius={8}></Box>
            <Box bgColor={numLine > 2 ? bk_done : bk_undone} h={'10px'} w={'180px'}></Box>

            <Box bgColor={numLine > 3 ? bk_done : bk_undone} h={'40px'} w={'40px'} borderRadius={8}></Box>
            <Box bgColor={numLine > 3 ? bk_done : bk_undone} h={'10px'} w={'180px'}></Box>
            <Flex bgColor={numLine > 3 ? bk_done : bk_undone} h={'40px'} w={'40px'} borderRadius={8} justifyContent={'center'} alignItems={'center'}>
                {numLine > 3 ? <Image src={check_png} width={'30px'} /> : ''}
            </Flex>
        </Flex>
    )
}
