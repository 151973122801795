import React, { useState } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react';
import { font1 } from '../localVars';
let chooses_answers = [];

export default function Question({ question, options, index, correct_answer }) {

    const [isClicked, setIsCLicked] = useState(false);

    const num_question = index === 0 ? 'السؤال الأول' : index === 1 ? 'السؤال الثاني' : index === 2 ? 'السؤال الثالث' : index === 3 ? 'السؤال الرابع' : '';
    return (
        <Flex width={'100%'} flexDir={'column'} justifyContent={'center'} alignItems={'center'}>
            <Text
                width={'86%'}
                dir='rtl'
                fontFamily={font1}
                fontSize={20}
                mt={'120px'}
                pb={4}
                mr={10}
            >{num_question}</Text>
            <Flex

                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                borderRadius={16}
                p={5}
                width={'700px'}
                pb={16}
                flexDir={'column'}
                mt={4}
                pt={10}
                dir='rtl'
            >
                <Text
                    fontFamily={font1}
                    fontSize={20}
                    alignSelf={'center'}
                    bgColor={'#3d0c6e'}
                    color={'#fff'}
                    pt={6}
                    pb={6}
                    pr={4}
                    pl={4}
                    borderRadius={16}
                >{question}</Text>


                {options.map((item, index) => (
                    <Option
                        txt={item}
                        number={index === 0 ? 'أ' : index === 1 ? 'ب' : index === 2 ? 'ج' : index === 3 ? 'د' : ''}
                        correct_answer={correct_answer}
                        question={question}
                        isClicked={isClicked}
                        setIsCLicked={setIsCLicked}
                    />

                ))}
            </Flex>
        </Flex>
    )
}

const Option = ({ txt, number, correct_answer, question, isClicked, setIsCLicked }) => {
    const statisColor = '#fff';
    const [bg, setBg] = useState(statisColor);
    const isCorrect = correct_answer === txt;


    const handleClick = () => {
        if (!isClicked) {
            setIsCLicked(true);


            const correctColor = '#8cc98f';
            const wrongColor = '#ff5261';

            if (isCorrect) {
                setBg(correctColor);
            }
            else {
                setBg(wrongColor);
            }
        }

    }
    return (
        <Flex
            width={'90%'}
            mt={number === 'أ' ? 10 : 6}

            alignSelf={'center'}
            dir='rtl'
            alignItems={'center'}
            justifyContent={'space-between'}
            cursor={'pointer'}
        >
            <Flex
                bgColor={'#3d0c6e'}
                borderRadius={8}
                height={'60px'}
                width={'50px'}
                justifyContent={'center'}
                alignItems={'center'}
                mr={2}

            >
                <Text fontSize={20} fontFamily={font1} color={'white'}>{number}</Text>
            </Flex>
            <Flex
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                borderRadius={8}
                width={'89%'}
                height={'60px'}
                alignItems={'center'}
                bgColor={bg}
                onClick={handleClick}

            >
                <Text
                    fontFamily={font1}
                    fontSize={18}
                    mr={6}
                >{txt}</Text>
            </Flex>
        </Flex>
    )
}
