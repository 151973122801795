import React from 'react'
import { Flex, Avatar, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { bk2, font1 } from '../localVars';
export default function AvatarName() {
    const { avatar, full_name } = useSelector((state) => state.auth)
    return (
        <Flex alignItems={'center'}>
            <Avatar name='Dan Abrahmov' src={avatar} />
            <Text
                fontFamily={font1}
                color={bk2}
                fontSize={20}
                ml={2}
            >{full_name}</Text>
        </Flex>
    )
}
