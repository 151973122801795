import React, { useEffect, useState } from 'react'
import { Flex, Text, Button, Image, Box, useToast } from '@chakra-ui/react';
import Sidebar from '../components/Sidebar';
import AvatarName from '../components/AvatarName';
import { font1 } from '../localVars';
import goal from '../assets/images/goal.png'
import { Icon } from '@iconify/react'
const data = [
    {
        "question": "ما إعراب كلمة 'الكتاب' في الجملة التالية: 'قرأتُ الكتابَ'؟",
        "options": [
            {
                "option": "مفعول به منصوب وعلامة نصبه الفتحة الظاهرة",
                "is_correct": true
            },
            {
                "option": "فاعل مرفوع وعلامة رفعه الضمة",
                "is_correct": false
            }
        ]
    },
    {
        "question": "ما إعراب كلمة 'الطالب' في الجملة التالية: 'نجح الطالبُ'؟",
        "options": [
            {
                "option": "فاعل مرفوع وعلامة رفعه الضمة",
                "is_correct": true
            },
            {
                "option": "مفعول به منصوب وعلامة نصبه الفتحة",
                "is_correct": false
            }
        ]
    },
    {
        "question": "ما إعراب كلمة 'المعلم' في الجملة التالية: 'أكرمتُ المعلمَ'؟",
        "options": [
            {
                "option": "مفعول به منصوب وعلامة نصبه الفتحة",
                "is_correct": true
            },
            {
                "option": "مبتدأ مرفوع وعلامة رفعه الضمة",
                "is_correct": false
            }
        ]
    },
    {
        "question": "ما إعراب كلمة 'العلماء' في الجملة التالية: 'احترمتُ العلماءَ'؟",
        "options": [
            {
                "option": "مفعول به منصوب وعلامة نصبه الفتحة",
                "is_correct": true
            },
            {
                "option": "خبر مرفوع وعلامة رفعه الضمة",
                "is_correct": false
            }
        ]
    },
    {
        "question": "ما إعراب كلمة 'الدرس' في الجملة التالية: 'فهمتُ الدرسَ'؟",
        "options": [
            {
                "option": "مفعول به منصوب وعلامة نصبه الفتحة",
                "is_correct": true
            },
            {
                "option": "فاعل مرفوع وعلامة رفعه الضمة",
                "is_correct": false
            }
        ]
    }
]
export default function RapidParsingChallenge() {
    const [page, setPage] = useState('view');

    return (
        <Flex direction="row-reverse" minH="100vh" width={'100%'} color={'#fff'}>
            <Sidebar page={'Home'} />
            {page === 'view' ? <View page={page} setPage={setPage} /> : <Challange page={page} setPage={setPage} />}

        </Flex>
    )
}

const View = ({ page, setPage }) => {
    return (
        <Flex flex="1" width={'84%'} flexDir={'column'} alignItems={'start'} color={'black'} pb={10} mr="16%" overflowY="auto">
            <Flex
                width={'94%'}
                alignSelf={'center'}
                mt={6}
                justifyContent={'space-between'}
                color={'black'}
            >
                <AvatarName />
            </Flex>

            <Flex
                mt={20}
                width={'280px'}
                alignSelf={'center'}
                height={'60px'}
                bgColor={'#1B0378'}
                borderRadius={20}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Text fontFamily={font1} fontSize={24} color={'#fff'}>تحدي الإعراب السريع </Text>
            </Flex>
            <Image
                src={goal}
                width={'160px'}
                alignSelf={'center'}
                mt={12}
            />

            <Flex dir='rtl' alignSelf={'center'} mt={10} alignItems={'center'}>
                <Box height={'6px'} width={'6px'} bgColor={'black'} ml={4} mt={2}></Box>
                <Text
                    alignSelf={'center'}
                    textAlign={'center'}

                    fontFamily={font1}
                    fontSize={24}
                >ستظهر لك مجموعة  من الأسئلة حاول حل أكبر قدر في أقل وقت ممكن </Text>
            </Flex>

            <Flex dir='rtl' alignSelf={'center'} mt={2} alignItems={'center'}>
                <Box height={'6px'} width={'6px'} bgColor={'black'} ml={4} mt={2}></Box>
                <Text
                    alignSelf={'center'}
                    textAlign={'center'}

                    fontFamily={font1}
                    fontSize={24}
                >سيظهر لك مؤقت زمني يقيس سرعتك في الإجابة على الأسئلة </Text>
            </Flex>

            <Text
                alignSelf={'center'}
                textAlign={'center'}
                mt={2}
                fontFamily={font1}
                fontSize={24}
            >في حال كنت جاهزا اضغط على زر البدء</Text>

            <Button
                bgColor={'#1B0378'}
                alignSelf={'center'}
                mt={10}
                color={'#fff'}
                fontFamily={font1}
                fontSize={24}
                width={'220px'}
                height={'54px'}
                borderRadius={20}
                _hover={{ opacity: 0.7 }}
                onClick={() => setPage('challange')}
            >
                البدء
            </Button>
        </Flex>
    )
}

const Challange = ({ page, setPage }) => {
    const [timeLeft, setTimeLeft] = useState(40);  // Initialize the timer to 40 seconds
    const [locked, setLocked] = useState(false);
    const handleNext = () => {
        setPage('results')
    }
    useEffect(() => {
        // If the timer reaches 0, set locked to true and stop the timer
        if (timeLeft === 0) {
            setLocked(true);
            return;
        }

        // Set up an interval to decrease the timeLeft every second
        const intervalId = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

        // Clean up the interval when the component unmounts or the timer stops
        return () => clearInterval(intervalId);
    }, [timeLeft]);
    return (
        <Flex flex="1" width={'84%'} flexDir={'column'} alignItems={'start'} color={'black'} pb={10} mr="16%" overflowY="auto">
            <Flex
                width={'94%'}
                alignSelf={'center'}
                mt={6}
                justifyContent={'space-between'}
                color={'black'}
            >
                <AvatarName />
            </Flex>
            <Text alignSelf={'center'} mt={10} fontFamily={font1} fontSize={24}>تحدي الإعراب السريع </Text>

            {page === 'challange' && <Text fontFamily={font1} fontSize={26} alignSelf={'center'} mt={20}>00:00:{timeLeft}</Text>}


            {page === 'challange' ? <Question locked={locked} setLocked={setLocked} timeLeft={timeLeft} page={page} setPage={setPage} /> : <Results />}

            {page === 'challange' && (
                <Flex width={'60%'} alignSelf={'center'} mt={16}>
                    <Button
                        bgColor={'#1B0378'}
                        color={'#fff'}
                        width={'200px'}
                        alignSelf={'center'}
                        dir='rtl'
                        mt={4}
                        fontFamily={font1}
                        fontSize={26}
                        height={'48px'}
                        borderRadius={12}
                        justifyContent="space-between"
                        rightIcon={<Icon icon={'ion:arrow-back'} width={28} />}
                        display={locked ? 'flex' : 'none'}
                        _hover={{ opacity: 0.7 }}
                        onClick={handleNext}

                    >
                        <Box flex="1" textAlign="center" >التالي</Box>
                    </Button>
                </Flex>
            )}

        </Flex>
    )
}

const Line = ({ done }) => {

    return (
        <Flex width={'200px'} height={'10px'} borderRadius={20} bgColor={done ? '#1B0378' : '#D9D9D9'}>

        </Flex>
    )
}

const Question = ({ locked, setLocked, timeLeft, page, setPage }) => {
    const [num, setNum] = useState(0);
    const [question, setQuestion] = useState('');
    const [options, setOptions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [correctAnswers, setCorrectAnswers] = useState([]);
    const [wrongAnswers, setWrongAnswers] = useState([]);
    const [finished, setFinished] = useState(false);
    const toast = useToast();
    useEffect(() => {
        setQuestion(data[num].question);
        setOptions(data[num].options);
    }, [num])

    const handleClick = (txt, is_correct) => {
        if (!locked) {
            if (is_correct) {
                !finished && correctAnswers.push(txt);
            }
            else {
                !finished && wrongAnswers.push(txt);
            }
            answers.push(txt);
            if (num === 4) {
                setFinished(true);
                setLocked(true);
                setPage('results')
                toast({
                    position: 'top',
                    title: `تم حل التحدي السريع في ${40 - timeLeft} ثواني`,

                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                console.log('Correct answers')
                console.log(correctAnswers);
                console.log('Wrong answers')
                console.log(wrongAnswers);
            }
            else {
                setNum(num + 1);
            }
        }


    }
    return (
        <>
            <Flex width={'84%'} alignSelf={'center'} justifyContent={'space-between'} mt={10} dir='rtl'>
                <Line done={true} />
                <Line done={num > 0 ? true : false} />
                <Line done={num > 1 ? true : false} />
                <Line done={num > 2 ? true : false} />
                <Line done={num > 3 ? true : false} />

            </Flex>
            <Text fontFamily={font1} fontSize={24} alignSelf={'center'} mt={10}>{question}</Text>

            {options.length > 0 && options.map((item, index) => (
                <Flex
                    width={'600px'}
                    height={'60px'}
                    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                    alignSelf={'center'}
                    mt={index === 0 ? 10 : 4}
                    borderRadius={12}
                    dir='rtl'
                    alignItems={'center'}
                    cursor={'pointer'}
                    onClick={() => handleClick(item.option, item.is_correct)}
                >
                    <Text fontFamily={font1} fontSize={24} mr={8}>{item.option}</Text>
                </Flex>
            ))}
        </>
    )
}

const Results = () => {
    return (
        <Flex flexDir={'column'} dir='rtl' alignItems={'center'} width={'100%'}>
            <Text fontFamily={font1} fontSize={22} width={'70%'} mt={10}>الإجابات الخاطئة التصحيح:</Text>
            <Flex
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                width={'70%'}
                height={'200px'}
                mt={4}
                borderRadius={12}
            ></Flex>

            <Text fontFamily={font1} fontSize={22} width={'70%'} mt={6}>التوضيح:</Text>
            <Flex
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                width={'70%'}
                height={'200px'}
                mt={4}
                borderRadius={12}
            ></Flex>
        </Flex>
    )
}