import React, { useState } from 'react'
import { Flex, Text, Button, Box } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import Sidebar from '../components/Sidebar';
import AvatarName from '../components/AvatarName';
import { font1 } from '../localVars';
import GmailExercise from '../components/GmailExercise';
import CradsExercise from '../components/CradsExercise';
import LineExercises from '../components/LineExercises';
export default function Exercises() {
    const [page, setPage] = useState('cards');
    const [numLine, setNumLine] = useState(1);
    return (
        <Flex direction="row-reverse" minH="100vh" width={'100%'} color={'#fff'}>
            <Sidebar page={'Exercises'} />

            <Flex flex="1" width={'84%'} flexDir={'column'} alignItems={'center'} pb={10} color={'black'} mr="16%" overflowY="auto">
                <Flex
                    width={'94%'}
                    alignSelf={'center'}
                    mt={6}
                    justifyContent={'space-between'}
                    color={'black'}
                >
                    <AvatarName />
                    <Icon icon={'mdi:arrow-right'} width={40} />
                </Flex>

                <Text
                    fontFamily={font1}
                    fontSize={26}
                    color={'#000'}
                >المسار التدريبي</Text>

                <LineExercises numLine={numLine} />

                {page === 'gmail' && <GmailExercise setPage={setPage} setNumLine={setNumLine} />}
                {page === 'cards' && <CradsExercise setPage={setPage} setNumLine={setNumLine} />}
            </Flex>
        </Flex>
    )
}
